import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Biogeografická pravidla" />
    <h1>Biogeografická pravidla</h1>
    <p>Biogeografick&aacute; pravidla jsou zobecňuj&iacute;c&iacute; z&aacute;konitosti popisuj&iacute;c&iacute; prostorov&eacute; rozd&iacute;ly v ekologick&yacute;ch populac&iacute;ch. Mezi nejzn&aacute;měj&scaron;&iacute; biogeografick&aacute; pravidla patř&iacute;:</p>
<ul>
<li><strong>Bergmanovo pravidlo</strong></li>
<li><strong>Allenovo pravidlo</strong></li>
<li><strong>Glogerovo pravidlo</strong></li>
<li><strong>Jordanovo pravidlo</strong></li>
<li><strong>Thorsonovo pravidlo</strong></li>
</ul>
<hr />
<h2>Vybran&aacute; biogeografick&aacute; pravidla</h2>
<h3>Bergmanovo pravidlo</h3>
<p>Bergamanovo pravidlo ř&iacute;k&aacute;, že v r&aacute;mci &scaron;iroce roz&scaron;&iacute;řen&eacute;ho taxonu savců a pt&aacute;ků se vět&scaron;&iacute; jedinci vyskytuj&iacute; ve vy&scaron;&scaron;&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řk&aacute;ch.</p>
<p>Původn&iacute; pravidlo z roku 1847 bylo později z endotermn&iacute;ch živočichů roz&scaron;&iacute;řeno i na dal&scaron;&iacute; skupiny (např. na někter&eacute; plazi a mravence), je zde v&scaron;ak mnoho v&yacute;jimek.</p>
<h3>Allenovo pravidlo</h3>
<p>Allenovo pravidlo z roku 1878 ř&iacute;k&aacute;, že endotermn&iacute; živočichov&eacute; žij&iacute;c&iacute;<br />ve vy&scaron;&scaron;&iacute;ch (chladněj&scaron;&iacute;ch) zeměpisn&yacute;ch &scaron;&iacute;řk&aacute;ch maj&iacute; zpravidla men&scaron;&iacute; těln&iacute; v&yacute;běžky (u&scaron;i, ocasy, zob&aacute;ky) a končetiny než jejich př&iacute;buzn&iacute; b&yacute;vaj&iacute;c&iacute; niž&scaron;&iacute; (teplej&scaron;&iacute;) zeměpisn&eacute; &scaron;&iacute;řky.</p>
<p>Allenovo pravidlo se vysvětluje t&iacute;m, že vět&scaron;&iacute; těln&iacute; v&yacute;běžky umožňuj&iacute; rychlej&scaron;&iacute; ochlazov&aacute;n&iacute; krve v teplej&scaron;&iacute;ch oblastech.</p>
<h3>Glogerovo pravidlo</h3>
<p>Glogerovo pravidlo z roku 1883 ř&iacute;k&aacute;, že zbarven&iacute; př&iacute;buzn&yacute;ch endotermn&iacute;ch živočichů koreluje s vlhkost&iacute; prostřed&iacute;, kter&eacute; ob&yacute;vaj&iacute;, přičemž tmav&scaron;&iacute; formy se vyskytuj&iacute; ve vlhč&iacute;m prostřed&iacute;.</p>
<p>Pravidlo pravděpodobně souvis&iacute; s lep&scaron;&iacute;m maskov&aacute;n&iacute;m druhů, protože ve vlhč&iacute;ch oblastech se vyskytuj&iacute; tmav&scaron;&iacute; půdy a hust&scaron;&iacute; vegetace. Z&aacute;roveň m&aacute; změna zbarven&iacute; zřejmě i pozitivn&iacute; vliv na termoregulaci v dan&yacute;ch prostřed&iacute;ch.</p>
<h3>Jordanovo pravidlo</h3>
<p>Jordanovo pravidlo z roku 1892 hovoř&iacute; o tom, že počet obratlů mořsk&yacute;ch ryb roste pod&eacute;l gradientu od tepl&yacute;ch vod v tropick&yacute;ch oblastech směrem k chladn&yacute;m vod&aacute;m ve vy&scaron;&scaron;&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řk&aacute;ch.</p>
<p>St&aacute;le nen&iacute; př&iacute;li&scaron; jasn&eacute;, do jak&eacute; m&iacute;ry je toto biogeografick&eacute; pravidlo univerz&aacute;ln&iacute;. Z&aacute;vislost se totiž měn&iacute; pro různ&eacute; druhy, skupiny druhů nebo populace. Zřejmě souvis&iacute; s Bergmanov&yacute;m biogeografick&yacute;m pravidlem.</p>
<h3>Thorsonovo pravidlo</h3>
<p>Thorsonovo biogeografick&eacute; pravidlo ř&iacute;k&aacute;, že v&yacute;voj mořsk&yacute;ch bezobratl&yacute;ch se měn&iacute; se vzrůstaj&iacute;c&iacute; zeměpisnou &scaron;&iacute;řkou od nepř&iacute;m&eacute;ho k př&iacute;m&eacute;mu.</p>
<p>U př&iacute;m&eacute;ho v&yacute;voje je mlad&yacute; jedinec po vyl&iacute;hnut&iacute; podobn&yacute; dospělci a pouze dorůst&aacute; jeho velikosti. Nepř&iacute;m&yacute; je charakterizov&aacute;n v&yacute;vojem přes larv&aacute;ln&iacute; st&aacute;dium, což může b&yacute;t v niž&scaron;&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řk&aacute;ch v&yacute;hodněj&scaron;&iacute;, protože může b&yacute;t un&aacute;&scaron;ena mořsk&yacute;mi proudy a druh se pak rychleji &scaron;&iacute;ř&iacute;.</p>
<p>Ve vy&scaron;&scaron;&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řk&aacute;ch se sp&iacute;&scaron;e u druhů vyskytuj&iacute; vět&scaron;&iacute; vaj&iacute;čka, obecně rychlej&scaron;&iacute; růst jedinců a z&aacute;roveň je častěj&scaron;&iacute; živorodost a př&iacute;m&yacute; v&yacute;voj.</p>
<h3>Dal&scaron;&iacute; biogeografick&aacute; pravidla</h3>
<h4>Vermeijova hypot&eacute;za predačn&iacute; eskalace</h4>
<p>Toto biogeografick&eacute; pravidlo zn&aacute;m&eacute; jako hypot&eacute;za predačn&iacute; eskalace ř&iacute;k&aacute;, že adaptace na predačn&iacute; tlak jsou l&eacute;pe vyvinut&eacute; v niž&scaron;&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řk&aacute;ch. Hypot&eacute;za z roku 1978 byla zkoum&aacute;na na mořsk&yacute;ch plž&iacute;ch, mlž&iacute;ch, houbovc&iacute;ch aj. Rovněž vyjadřuje, že v niž&scaron;&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řk&aacute;ch je vět&scaron;&iacute; predačn&iacute; tlak.</p>
<p>Na sou&scaron;&iacute; je Vermeijova hypot&eacute;za zdokumentov&aacute;na pro velikost semen, kter&aacute; jsou m&iacute;rně vět&scaron;&iacute; v niž&scaron;&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řk&aacute;ch. Vět&scaron;&iacute; semena l&eacute;pe odol&aacute;vaj&iacute; predaci.</p>
<h4>Renschovo pravidlo geografick&eacute; variace životn&iacute;ch charakteristik</h4>
<p>Renschovo pravidlo mimo jin&eacute; popisuje, že velikost snů&scaron;ky pt&aacute;ků, velikost vrhů u savců či dlouhověkost se měn&iacute; nejen latitud&aacute;lně, ale v temper&aacute;tn&iacute;ch oblastech tak&eacute; mezi ostrovy a sou&scaron;&iacute;.</p>
<p>Obecně lze vypozorovat, že v nestabiln&iacute;ch podm&iacute;nk&aacute;ch (zejm&eacute;na pevnina severn&iacute;ch &scaron;&iacute;řek) je tendence k <Link to="/ekologie-zivotni-prostredi/zivotni-strategie/#r-K-strategie">r-strategii</Link>. Tendence ke <Link to="/ekologie-zivotni-prostredi/zivotni-strategie/#r-K-strategie">K-strategii</Link> je pak ve stabilněj&scaron;&iacute;ch klimatech, zejm&eacute;na v tropick&yacute;ch oblastech a na ostrovech.</p>
    <hr />
  <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Biogeografická pravidla</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/ekologicka/bioticke-interakce/"><button className="inv">&larr; Biotické interakce</button></Link>
    <Link to="/biogeografie/evolucni/"><button className="inv">Evoluční biogeografie &rarr;</button></Link>
    </BtnWrap>
  </div></ImgCon><hr />
  </Layout>
)
